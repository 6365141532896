var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from '../custom-breadcrumbs';
import { useSettingsContext } from '../settings';
var ContainerLayout = function (_a) {
    var heading = _a.heading, helmet = _a.helmet, links = _a.links, action = _a.action, moreLink = _a.moreLink, activeLast = _a.activeLast, children = _a.children;
    var themeStretch = useSettingsContext().themeStretch;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: helmet }), _jsx(Container, __assign({ maxWidth: themeStretch ? false : 'lg' }, { children: _jsxs(_Fragment, { children: [_jsx(CustomBreadcrumbs, { heading: heading, links: links, action: action, moreLink: moreLink, activeLast: activeLast }), children] }) }))] }));
};
export default ContainerLayout;
